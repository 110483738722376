<template>
  <div>
    <div class="flex justify-end">
      <sun-button variant="pill" class="text-xs right-0 custom-p-1" :disabled="readOnly" @click="goToNewCreative">
        + New creative
      </sun-button>
    </div>
    <sun-data-table :headers="headers" :content="items" hoverable :loading="isLoading" class="mt-2">
      <template #[`col.image`]="{ item }">
        <sun-data-table-cell>
          <video v-if="item.urlFile" class="h-10">
            <source :src="item.urlFile" type="video/mp4" />
          </video>
          <img
            v-else-if="item.vast"
            alt="vast image"
            class="h-10"
            src="https://via.placeholder.com/450x450?text=VAST"
          />
        </sun-data-table-cell>
      </template>

      <template #[`col.status`]="{ item }">
        <sun-data-table-cell>
          <status-pill :status="item.status.value" />
        </sun-data-table-cell>
      </template>

      <template #[`col.actions`]="{ item, columnClass }">
        <sun-data-table-cell :class="columnClass">
          <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
        </sun-data-table-cell>
      </template>

      <template #empty>
        <asterix-no-data class="bg-white text-center" />
      </template>
    </sun-data-table>

    <delete-modal :open="showModal" :loading="isLoading" @cancel="showModal = false" @confirm="deleteCreative">
      <template #description>{{ deleteModalDescription }}</template>
    </delete-modal>

    <router-view v-bind="$attrs" @update="getTableItems" @cancel="onCancelModal" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { SocialAudienceApi } from '@/services/api/SocialAudienceApi';
import { getCreatives, deleteCreative } from '@/services/modules/socialAudience/creative/video';
import { edit } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative/edit';
import { create } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative/create';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';
import StatusPill from '@/components/atoms/StatusPill';
import { CONFIG } from './config';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'CreativeListVideo',
  components: {
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
    StatusPill,
  },
  mixins: [deleteModalMixin],
  props: {
    campaignId: {
      type: String,
      default: () => null,
    },
    insertionId: {
      type: String,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    headers: CONFIG.defaultColumns,
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    items: [],
    currentApi: new SocialAudienceApi(),
    deleteModalDescription: '',
    isLoading: false,
    creativeToDelete: null,
  }),
  async created() {
    if (this.insertionId) await this.getTableItems();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async onActionClick(event, item) {
      if (this.readOnly) {
        this.createToast(Toast.error('Error', 'The campaign has been completed'));
        return;
      }

      switch (event.name) {
        case 'Edit':
          this.routeToEditCreative(item);
          break;
        case 'Delete':
          this.routeToDeleteCreative(item);
          break;
      }
    },
    routeToDeleteCreative(item) {
      this.deleteModalDescription = `The creative is going to be deleted.`;
      this.showModal = true;
      this.creativeToDelete = item;
      this.openModal({ id: item.id });
    },
    routeToEditCreative(item) {
      this.$router.push({
        ...edit,
        params: {
          creativeId: item.id,
        },
      });
    },
    async getTableItems() {
      try {
        this.isLoading = true;
        const { data } = await getCreatives(this.insertionId);
        this.items = data;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get creatives.`, error.message));
        }
      } finally {
        this.isLoading = false;
      }
    },
    async deleteCreative() {
      try {
        this.isLoading = true;
        await deleteCreative(this.creativeToDelete);
        this.createToast(Toast.success('Creative deleted', `The creative was deleted successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Creative not deleted', error.message));
      } finally {
        this.isLoading = false;
        this.showModal = false;
        this.getTableItems();
      }
    },
    async goToNewCreative() {
      await this.$router.push({
        ...create,
        params: {
          insertionId: this.insertionId,
        },
      });
    },
    async onCancelModal() {
      await this.$router.push(creative);
    },
  },
};
</script>

import Column from '@/model/shared/Column';

export const COLUMNS = {
  NAME: new Column('NAME').setClass('font-bold'),
  IMAGE: new Column('IMAGE'),
  IMPRESSIONS: new Column('IMPRESSIONS').toRight(),
  VIEWS: new Column('VIEWS 100%', 'views').toRight(),
  STATUS: new Column('STATUS'),
  ACTIONS: new Column('ACTIONS').setClass('w-4'),
};

export const CONFIG = {
  defaultColumns: [COLUMNS.NAME, COLUMNS.STATUS, COLUMNS.IMAGE, COLUMNS.IMPRESSIONS, COLUMNS.VIEWS, COLUMNS.ACTIONS],
};

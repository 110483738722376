<template>
  <div class="flex button-group">
    <button
      v-for="option in options"
      :key="option[trackBy]"
      :disabled="disabled || selected === option[trackBy]"
      class="item"
      :class="{
        selected: selected === option[trackBy],
        disabled: !!option.disabled,
      }"
      @click.prevent.stop="onOptionClick(option)"
    >
      <slot name="option" :option="option">
        {{ option[textBy] }}
      </slot>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonGroup',
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    textBy: {
      type: String,
      default: 'name',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [String, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selected: null,
  }),
  watch: {
    value: 'initialSelection',
  },
  created() {
    this.initialSelection(this.value);
  },
  methods: {
    initialSelection(option) {
      this.selected = this.returnObject ? option[this.trackBy] : option;
    },
    onOptionClick(option) {
      if (!option?.disabled) {
        this.selected = option[this.trackBy];

        const rta = this.returnObject ? option : this.selected;
        this.$emit('input', rta);
        this.$emit('change', rta);
      }
    },
  },
};
</script>

<style scoped>
.button-group .item:first-child {
  @apply rounded-tl-md rounded-bl-md;
}
.button-group .item:last-child {
  @apply rounded-tr-md rounded-br-md border-r;
}
.button-group .item.selected {
  @apply bg-gray-700 text-white border-gray-700;
}
.button-group .item.selected:hover {
  @apply bg-gray-900;
}
.button-group .item.selected + .item {
  border-left-color: transparent;
}
.button-group .item:hover {
  @apply bg-gray-200;
}
.button-group .item {
  @apply text-gray-700 border border-gray-400 border-r-0 text-sm px-2 py-1;
}
.button-group .item.disabled:hover,
.button-group .item.disabled {
  @apply cursor-not-allowed bg-gray-400;
}
</style>

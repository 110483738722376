var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      class: `px-2 py-1 leading-tight align-middle inline-flex items-center bg-${_vm.computedColor}-100 rounded-full`,
    },
    [
      _c(
        "svg",
        {
          class: `h-2 w-2 text-${_vm.computedColor}-500`,
          attrs: { fill: "currentColor", viewBox: "0 0 8 8" },
        },
        [_c("circle", { attrs: { cx: "4", cy: "4", r: "3" } })]
      ),
      _c(
        "span",
        { class: `ml-2 text-xs font-bold text-${_vm.computedColor}-900` },
        [_vm._v(_vm._s(_vm.status))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
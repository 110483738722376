var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "flex justify-end" },
        [
          _c(
            "sun-button",
            {
              staticClass: "text-xs right-0 custom-p-1",
              attrs: { variant: "pill", disabled: _vm.readOnly },
              on: { click: _vm.goToNewCreative },
            },
            [_vm._v(" + New creative ")]
          ),
        ],
        1
      ),
      _c("sun-data-table", {
        staticClass: "mt-2",
        attrs: {
          headers: _vm.headers,
          content: _vm.items,
          hoverable: "",
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u(
          [
            {
              key: `col.image`,
              fn: function ({ item }) {
                return [
                  _c("sun-data-table-cell", [
                    item.urlFile
                      ? _c("video", { staticClass: "h-10" }, [
                          _c("source", {
                            attrs: { src: item.urlFile, type: "video/mp4" },
                          }),
                        ])
                      : item.vast
                      ? _c("img", {
                          staticClass: "h-10",
                          attrs: {
                            alt: "vast image",
                            src: "https://via.placeholder.com/450x450?text=VAST",
                          },
                        })
                      : _vm._e(),
                  ]),
                ]
              },
            },
            {
              key: `col.status`,
              fn: function ({ item }) {
                return [
                  _c(
                    "sun-data-table-cell",
                    [
                      _c("status-pill", {
                        attrs: { status: item.status.value },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `col.actions`,
              fn: function ({ item, columnClass }) {
                return [
                  _c(
                    "sun-data-table-cell",
                    { class: columnClass },
                    [
                      _c("table-action-menu", {
                        attrs: {
                          actions: _vm.actions,
                          item: item,
                          items: _vm.items,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onActionClick($event, item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "empty",
              fn: function () {
                return [
                  _c("asterix-no-data", {
                    staticClass: "bg-white text-center",
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal, loading: _vm.isLoading },
        on: {
          cancel: function ($event) {
            _vm.showModal = false
          },
          confirm: _vm.deleteCreative,
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteModalDescription))]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "router-view",
        _vm._b(
          { on: { update: _vm.getTableItems, cancel: _vm.onCancelModal } },
          "router-view",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
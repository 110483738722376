var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.campaign.type.id === _vm.NATIVE_TYPE.id
        ? _c(
            "creative-list-native",
            _vm._b(
              { attrs: { "campaign-type": _vm.campaign.type.id } },
              "creative-list-native",
              _vm.$attrs,
              false
            )
          )
        : _vm.campaign.type.id === _vm.VIDEO_TYPE.id
        ? _c(
            "creative-list-video",
            _vm._b(
              { attrs: { "campaign-type": _vm.campaign.type.id } },
              "creative-list-video",
              _vm.$attrs,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { api } from '.';
import { INSERTION_RESOURCE } from '@/services/keys';

export const getResources = insertionId => ({
  [INSERTION_RESOURCE]: insertionId,
});

/**
 *
 * @param {Insertion} insertion
 * @returns {Promise.<object>}
 */
export function updateInsertion(insertion) {
  const partialUrl = api.createUrl(getResources(insertion.id));
  return api.update(partialUrl, insertion.payload());
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex button-group" },
    _vm._l(_vm.options, function (option) {
      return _c(
        "button",
        {
          key: option[_vm.trackBy],
          staticClass: "item",
          class: {
            selected: _vm.selected === option[_vm.trackBy],
            disabled: !!option.disabled,
          },
          attrs: {
            disabled: _vm.disabled || _vm.selected === option[_vm.trackBy],
          },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.onOptionClick(option)
            },
          },
        },
        [
          _vm._t(
            "option",
            function () {
              return [_vm._v(" " + _vm._s(option[_vm.textBy]) + " ")]
            },
            { option: option }
          ),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
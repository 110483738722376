var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex relative" },
    [
      _c("sun-input", {
        attrs: {
          disabled: _vm.disabled,
          required: "required",
          type: "number",
          placeholder: "0",
          "text-error": _vm.messageError,
          error: _vm.error,
        },
        on: { change: _vm.onChange, enter: _vm.onChange },
        model: {
          value: _vm.input,
          callback: function ($$v) {
            _vm.input = $$v
          },
          expression: "input",
        },
      }),
      _c("button-group", {
        staticClass: "bg-white absolute rounded-lg z-1 h-10 shadow right-0",
        attrs: {
          disabled: _vm.disabled,
          options: _vm.options,
          "text-by": _vm.textBy,
          "track-by": _vm.trackBy,
          "return-object": "",
        },
        on: {
          change: function ($event) {
            return _vm.onChange()
          },
        },
        model: {
          value: _vm.btnSelected,
          callback: function ($$v) {
            _vm.btnSelected = $$v
          },
          expression: "btnSelected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <meta-actions-header class="mb-4" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700" :class="{ 'text-orange-500': !advertiserId }">
          <span v-if="advertiserId"
            >:
            <span v-if="advertiser" class="text-orange-500">
              {{ advertiser.name }}
            </span>
          </span>
        </breadcrumb>
      </template>
    </meta-actions-header>
    <div class="flex flex-col p-6 text-left bg-white rounded rounded-lg shadow-md lg:p-12">
      <card-form-loading v-if="isLoading || !advertiser" :rows="3" class="mt-10"></card-form-loading>
      <card-form-loading v-if="isLoading || !advertiser" :rows="3" class="mt-10"></card-form-loading>

      <sun-form v-else @submit="submitForm" @keypress.enter="submitForm">
        <card-form>
          <template #title>Basic Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Name">
                  <asterix-input
                    id="advertiser-name"
                    v-model="advertiser.name"
                    type="text"
                    name="name"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="A name..."
                    required="required"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="Email">
                  <sun-input
                    v-model="advertiser.email"
                    type="email"
                    placeholder="email@agency.com"
                    text-error="Email must be valid"
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>

            <category-selector v-model="advertiser" />

            <form-row>
              <template #left>
                <sun-label-group text="TAX ID">
                  <asterix-input
                    id="advertiser-tax-id"
                    v-model="advertiser.taxId"
                    type="text"
                    name="name"
                    validate-on-blur
                    :maxlength="15"
                    placeholder="An id..."
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <card-form>
          <template #title>Adress Info</template>
          <template #form>
            <form-row>
              <template #left>
                <sun-label-group text="Street">
                  <asterix-input
                    id="advertiser-street"
                    v-model="advertiser.address.street"
                    type="text"
                    name="street"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="Street"
                    required="required"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="City">
                  <asterix-input
                    id="advertiser-city"
                    v-model="advertiser.address.city"
                    type="text"
                    name="city"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="City"
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row v-if="advertiser">
              <template #left>
                <sun-label-group text="Country">
                  <sun-select
                    :id="`country-select`"
                    v-model="country"
                    name="country"
                    :options="countries"
                    track-by="id"
                    label="name"
                    add-hex-color="orange"
                    :text-error="formErrors.country"
                    required="required"
                    @input="changeCountry"
                  />
                </sun-label-group>
              </template>
              <template #right>
                <sun-label-group text="County">
                  <asterix-input
                    id="advertiser-county"
                    v-model="advertiser.address.county"
                    type="text"
                    name="county"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="County"
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>
            <form-row v-if="advertiser">
              <template #left>
                <sun-label-group text="Postal code">
                  <asterix-input
                    id="advertiser-postal-code"
                    v-model="advertiser.address.postalCode"
                    type="text"
                    name="postalCode"
                    :minlength="3"
                    :maxlength="50"
                    validate-on-blur
                    placeholder="Postal code"
                    required="required"
                  />
                </sun-label-group>
              </template>
            </form-row>
          </template>
        </card-form>
        <div class="flex w-1/2 mt-12">
          <save-button id="advertiser-submit" :loading="isLoading" />
        </div>
      </sun-form>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { CONTEXTS } from '@/model/shared/contexts';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import {
  createAdvertiser,
  deleteAdvertiser,
  getAdvertiserById,
  updateAdvertiser,
} from '@/services/modules/socialAudience/advertiser';
import { Toast } from '@/model/shared/Toast';
import Advertiser from '@/entities/socialAudience/Advertiser';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import metaInfo from '@/mixins/common/metaInfo';
import SaveButton from '@/components/atoms/SaveButton';
import { list as listAdvertiserRoute } from '@/router/private/modules/socialAudience/demand/advertiser/list';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import { ERRORS } from '@/i18n/forms/errors';
import AsterixInput from '@/components/atoms/AsterixInput';
import { COUNTRY_NS, COUNTRIES_KEY, COUNTRY_BY_ID, GET_COUNTRIES_REQUEST } from '@/store/modules/country/keys';
import Breadcrumb from '@/components/atoms/Breadcrumb';
import CategorySelector from '@/components/molecules/shared/CategorySelector';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';

export default {
  name: 'AdvertiserEdit',
  components: {
    Breadcrumb,
    FormRow,
    CardForm,
    SaveButton,
    MetaActionsHeader,
    CardFormLoading,
    AsterixInput,
    CategorySelector,
  },
  mixins: [metaInfo],
  props: {
    advertiserId: { type: String, default: () => null },
  },
  data: () => ({
    isLoading: false,
    advertiser: null,
    formErrors: {
      name: null,
      user: null,
      country: ERRORS.en.selectOption,
    },
    country: null,
  }),
  computed: {
    ...mapState(COUNTRY_NS, {
      countries: COUNTRIES_KEY,
    }),
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
      getCountryById: COUNTRY_BY_ID,
      categories: CATEGORIES,
    }),
  },
  async created() {
    this.isLoading = true;
    try {
      if (this.advertiserId) {
        await this.getAdvertiser();
      } else {
        this.advertiser = new Advertiser(this.activeClient);
      }
      await this.getCountryList();
    } catch (error) {
      this.createToast(Toast.error('Error to load advertiser', error.message));
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
      getCountries: GET_COUNTRIES_REQUEST,
      getCategoriesAndSubcategories: GET_CATEGORIES_REQUEST,
    }),
    metaTitleReplacement() {
      return { advertiser: this.advertiser?.name };
    },
    async getAdvertiser() {
      const { data } = await getAdvertiserById(this.advertiserId);
      await this.getCategoriesAndSubcategories();
      const categoryWithSubCategories = this.categories.find(cat => cat.id === data.category?.id);
      data.category = categoryWithSubCategories;
      this.advertiser = data;
      this.updateTitle();
    },
    async getCountryList() {
      await this.getCountries();
      if (this.advertiser.id) {
        this.country = this.getCountryById(this.advertiser.address.country);
      }
    },
    changeCountry(country) {
      this.advertiser.address.country = country.id;
    },
    async deleteAdvertiser() {
      try {
        this.isLoading = true;
        await deleteAdvertiser(this.advertiser.id);
        this.createToast(Toast.success('Delete done', `Delete of ${this.advertiser.name} was successfully`));
        await this.$router.replace({
          name: `${CONTEXTS.SOCIAL_AUDIENCE.baseUrl}-advertisers`,
        });
      } catch (error) {
        this.createToast(Toast.error('Delete was not possible', error.message));
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm({ valid }) {
      if (!valid) return;
      try {
        this.isLoading = true;
        if (this.advertiserId) {
          await updateAdvertiser(this.advertiser);
          this.createToast(
            Toast.success('Advertiser updated', `Advertiser ${this.advertiser.name} was updated successfully.`)
          );
        } else {
          await createAdvertiser(this.advertiser);
          this.createToast(
            Toast.success('Advertiser created', `Advertiser ${this.advertiser.name} was created successfully.`)
          );
        }
        await this.$router.push(listAdvertiserRoute);
      } catch (error) {
        this.createToast(Toast.error('Advertiser not updated', error.message));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #header-right>
        <router-link :to="newAdvertiserRoute">
          <sun-button variant="pill" class="px-4 text-xs bg-gray-700 custom-p-1 hover:bg-gray-800" color="gray">
            + New Advertiser
          </sun-button>
        </router-link>
      </template>

      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick['name']" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              :minlength="3"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.category`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              :track-by="filter.trackBy"
              :label="filter.textBy"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.subCategory`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="categoriesList"
              close-on-select
              disable-selected-options
              :track-by="filter.trackBy"
              :label="filter.textBy"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              group-label="name"
              group-values="subcategories"
              title-tooltip
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>

          <template #[`filter.totalCost`]="{ filter, onSelect, value, isDuplicate }">
            <div class="flex">
              <cost-filter
                :options="filter.options"
                :value="value[0] ? value[0].meta : filter.options[0]"
                :text-error="filtersGetFilterError(filter, isDuplicate)"
                :error="!!filtersGetFilterError(filter, isDuplicate)"
                @change="filtersOnSelectFilter(filter, $event, onSelect)"
                @enter="filtersOnSelectFilter(filter, $event, onSelect)"
              />
            </div>
          </template>

          <template #[`filter.last30DaysCost`]="{ filter, onSelect, value, isDuplicate }">
            <div class="flex">
              <cost-filter
                :options="filter.options"
                :value="value[0] ? value[0].meta : filter.options[0]"
                :text-error="filtersGetFilterError(filter, isDuplicate)"
                :error="!!filtersGetFilterError(filter, isDuplicate)"
                @change="filtersOnSelectFilter(filter, $event, onSelect)"
                @enter="filtersOnSelectFilter(filter, $event, onSelect)"
              />
            </div>
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          :loading="isLoading"
          class="w-full"
          @sort="onSortTable"
        >
          <template #[`col.category.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.category ? item.category.name : '' }}
            </sun-data-table-cell>
          </template>

          <template #[`col.subCategory.name`]="{ item }">
            <sun-data-table-cell>
              {{ item.subcategory ? item.subcategory.name : '' }}
            </sun-data-table-cell>
          </template>

          <template #[`col.last30DaysCost`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              {{ item.lastCost }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item }">
            <sun-data-table-cell>
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination
          class="justify-center mt-12"
          :total-pages="totalPages"
          :current-page="currentPage"
          @change="goToPage"
        />
      </template>
    </asterix-section>

    <delete-modal :open="showModal" @cancel="closeDeleteModal" @confirm="deleteElement">
      <template #description> {{ deleteModalDescription }}</template>
    </delete-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ADVERTISER_RESOURCE } from '@/services/keys';
import { CATEGORIES, GET_CATEGORIES_REQUEST } from '@/store/modules/category/keys';
import AsterixSection from '@/components/templates/AsterixSection';
import { indexMixin } from '@/mixins/index/indexMixin';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { getAdvertisers } from '@/services/modules/socialAudience/advertiser';
import { CONTEXTS } from '@/model/shared/contexts';
import { create } from '@/router/private/modules/socialAudience/demand/advertiser/create';
import { deepClone } from '@/utils/deepClone';
import { SocialAudienceApi } from '@/services/api/SocialAudienceApi';
import { create as createCampaign } from '@/router/private/modules/socialAudience/demand/campaign/create';
import CostFilter from '@/components/molecules/modules/socialAudience/CostFilter.vue';
import CONFIG from './config';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'AdvertiserList',
  components: {
    AsterixSection,
    CostFilter,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: { name: undefined },
    }),
    indexMixin,
    deleteModalMixin,
  ],
  data: () => ({
    headers: CONFIG.defaultColumns,
    actions: [{ name: 'Create a campaign' }, { name: 'Edit' }, { name: 'Delete' }],
    items: [],
    currentApi: new SocialAudienceApi(),
    deleteModalDescription: '',
    resource: ADVERTISER_RESOURCE,
    newAdvertiserRoute: create,
  }),
  computed: {
    ...mapGetters({
      categories: CATEGORIES,
    }),
    categoriesList() {
      return deepClone(this.categories);
    },
  },
  created() {
    this.getCategoriesAndSubcategories();
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({ getCategoriesAndSubcategories: GET_CATEGORIES_REQUEST }),

    async onActionClick(event, item) {
      switch (event.name) {
        case 'Create a campaign':
          this.routeToCreateCampaign(item.id);
          break;
        case 'Edit':
          this.routeToEditAdvertiser(item);
          break;
        case 'Delete':
          this.routeToDeleteAdvertiser(item);
          break;
      }
    },
    routeToDeleteAdvertiser(item) {
      this.deleteModalDescription = `Advertiser "${item.name}" is going to be deleted.`;
      this.showModal = true;
      this.selectedId = item.id;
      this.openModal({ id: item.id });
    },
    routeToEditAdvertiser(item) {
      this.$router.push({
        name: `${CONTEXTS.SOCIAL_AUDIENCE.baseUrl}-edit-advertiser`,
        params: { advertiserId: item.id },
      });
    },
    routeToCreateCampaign(advertiserId) {
      this.$router.push({
        name: createCampaign.name,
        query: {
          advertiserId,
        },
      });
    },
    closeDeleteModal() {
      this.selectedId = null;
      this.showModal = false;
    },
    deleteElement() {
      this.deleteElementById();
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getAdvertisers);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .button-group .item:first-child {
  @apply rounded-none;
}
::v-deep .button-group .item:last-child {
  @apply rounded-r;
}
::v-deep .button-group .item {
  @apply px-4 border-gray-300;
}
</style>

<template>
  <div>
    <creative-list-native
      v-if="campaign.type.id === NATIVE_TYPE.id"
      v-bind="$attrs"
      :campaign-type="campaign.type.id"
    ></creative-list-native>
    <creative-list-video
      v-else-if="campaign.type.id === VIDEO_TYPE.id"
      v-bind="$attrs"
      :campaign-type="campaign.type.id"
    ></creative-list-video>
  </div>
</template>

<script>
import CreativeListNative from '@/views/private/modules/socialAudience/creative/native/CreativeListNative';
import CreativeListVideo from '@/views/private/modules/socialAudience/creative/video/CreativeListVideo';
import { NATIVE_TYPE, VIDEO_TYPE } from '@/model/modules/socialAudience/campaign/CampaignTypes';
import Campaign from '@/entities/socialAudience/Campaign';

export default {
  name: 'CreativeListHandler',
  components: {
    CreativeListNative,
    CreativeListVideo,
  },
  props: {
    campaign: {
      type: Campaign,
      default: () => null,
    },
  },
  computed: {
    NATIVE_TYPE: () => NATIVE_TYPE,
    VIDEO_TYPE: () => VIDEO_TYPE,
  },
};
</script>

import Column from '@/model/shared/Column';
import FILTERS_CONFIG from '@/model/modules/socialAudience/filtersConfig.js';

export const COLUMNS = {
  NAME: new Column('NAME', 'name').asSort().setClass('font-bold'),
  CATEGORY: new Column('CATEGORY', 'category.name').asSort(),
  SUBCATEGORY: new Column('SUBCATEGORY', 'subCategory.name').asSort(),
  TOTAL_COST: new Column('TOTAL COST', 'totalCost').asSort().toRight(),
  LAST_30_DAYS_COST: new Column('LAST 30 DAYS COST', 'last30DaysCost').asSort().toRight(),
  ACTIVE_CAMPAIGNS: new Column('ACTIVE CAMPAIGNS', 'activeCampaignsCount').asSort().toRight(),
  ACTIONS: new Column('ACTIONS', 'actions'),
};

export default {
  defaultColumns: [
    COLUMNS.NAME,
    COLUMNS.CATEGORY,
    COLUMNS.SUBCATEGORY,
    COLUMNS.TOTAL_COST,
    COLUMNS.LAST_30_DAYS_COST,
    COLUMNS.ACTIVE_CAMPAIGNS,
    COLUMNS.ACTIONS,
  ],
  filters: [
    FILTERS_CONFIG.NAME,
    FILTERS_CONFIG.CATEGORY,
    FILTERS_CONFIG.SUB_CATEGORY,
    FILTERS_CONFIG.TOTAL_COST,
    FILTERS_CONFIG.LAST30_DAYS_COST,
  ],
};

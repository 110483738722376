var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.template
        ? _c(
            "div",
            { staticClass: "mb-8" },
            [
              _c(
                "sun-form",
                { on: { submit: _vm.submitTemplate } },
                [
                  _c("card-form", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function () {
                            return [_vm._v("Template")]
                          },
                          proxy: true,
                        },
                        {
                          key: "form",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto",
                                },
                                [
                                  _c("form-row", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "left",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                { attrs: { text: "Title" } },
                                                [
                                                  _c("asterix-input", {
                                                    attrs: {
                                                      id: "template-title",
                                                      disabled: _vm.readOnly,
                                                      type: "text",
                                                      name: "title",
                                                      minlength: 3,
                                                      maxlength: 50,
                                                      "validate-on-blur": "",
                                                      placeholder: "A title...",
                                                      required: "required",
                                                    },
                                                    model: {
                                                      value: _vm.template.name,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.template,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "template.name",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "right",
                                          fn: function () {
                                            return [
                                              _c(
                                                "sun-label-group",
                                                {
                                                  attrs: {
                                                    text: "Description",
                                                  },
                                                },
                                                [
                                                  _c("sun-text-area", {
                                                    attrs: {
                                                      id: "template-description",
                                                      disabled: _vm.readOnly,
                                                      value:
                                                        _vm.template
                                                          .description,
                                                      type: "text",
                                                      name: "description",
                                                      minlength: 3,
                                                      maxlength: 100,
                                                      placeholder:
                                                        "A description...",
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.template.description =
                                                          $event.value
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      4100945533
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "flex w-1/2 mt-2" },
                                [
                                  _c("save-button", {
                                    attrs: {
                                      id: "template-submit",
                                      disabled: _vm.readOnly,
                                      loading: _vm.isLoading,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      1014739229
                    ),
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex justify-end" },
        [
          _c(
            "sun-button",
            {
              staticClass: "text-xs right-0 custom-p-1",
              attrs: { variant: "pill", disabled: _vm.readOnly },
              on: { click: _vm.goToNewCreative },
            },
            [_vm._v(" + New creative ")]
          ),
        ],
        1
      ),
      _c("sun-data-table", {
        staticClass: "mt-2",
        attrs: {
          headers: _vm.headers,
          content: _vm.items,
          hoverable: "",
          loading: _vm.isLoading,
        },
        scopedSlots: _vm._u(
          [
            {
              key: `col.title`,
              fn: function ({ columnClass }) {
                return [
                  _c("sun-data-table-cell", { class: columnClass }, [
                    _vm._v(" " + _vm._s(_vm.insertion.template.name) + " "),
                  ]),
                ]
              },
            },
            {
              key: `col.image`,
              fn: function ({ item }) {
                return [
                  _c("sun-data-table-cell", { staticClass: "font-bold" }, [
                    _c("img", {
                      attrs: {
                        alt: "creative image",
                        src: item.urlFile,
                        width: "70",
                      },
                    }),
                  ]),
                ]
              },
            },
            {
              key: `col.status`,
              fn: function ({ item }) {
                return [
                  _c(
                    "sun-data-table-cell",
                    [
                      _c("status-pill", {
                        attrs: { status: item.status.value },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `col.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "sun-data-table-cell",
                    [
                      _c("table-action-menu", {
                        attrs: {
                          actions: _vm.actions,
                          item: item,
                          items: _vm.items,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onActionClick($event, item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "empty",
              fn: function () {
                return [
                  _c("asterix-no-data", {
                    staticClass: "bg-white text-center",
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
      _c("delete-modal", {
        attrs: { open: _vm.showModal, loading: _vm.isLoading },
        on: {
          cancel: function ($event) {
            _vm.showModal = false
          },
          confirm: _vm.deleteCreative,
        },
        scopedSlots: _vm._u([
          {
            key: "description",
            fn: function () {
              return [_vm._v(_vm._s(_vm.deleteModalDescription))]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "router-view",
        _vm._b(
          {
            on: {
              cancel: _vm.onCancelModal,
              "new-template": _vm.newTemplate,
              update: _vm.getTableItems,
            },
          },
          "router-view",
          _vm.$attrs,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { api } from '.';
import { CAMPAIGNS_RESOURCE, INSERTION_RESOURCE } from '@/services/keys';
import ResponseBuilder from '@/model/shared/ResponseBuilder';
import socialAudienceEntityParser from '@/entities/socialAudience/SocialAudienceEntityParser';

/**
 * @param {string} campaignId
 */
export const getResources = campaignId => ({
  [CAMPAIGNS_RESOURCE]: campaignId,
  [INSERTION_RESOURCE]: undefined,
});

/**
 * @param {string} campaignId
 * @returns {Promise.<ResponseBuilder>}
 */
export async function getInsertionByCampaign(campaignId) {
  const partialUrl = api.createUrl(getResources(campaignId));

  const { data } = await api.get(partialUrl);
  return new ResponseBuilder(socialAudienceEntityParser.parse(data), data.meta).build();
}

import Column from '@/model/shared/Column';

export const COLUMNS = {
  TITLE: new Column('TITLE').setClass('font-bold'),
  IMAGE: new Column('IMAGE'),
  CLICKS: new Column('CLICKS').toRight(),
  IMPRESSIONS: new Column('IMPRESSIONS').toRight(),
  STATUS: new Column('STATUS'),
  ACTIONS: new Column('ACTIONS').setClass('w-4'),
};

export const CONFIG = {
  defaultColumns: [COLUMNS.TITLE, COLUMNS.STATUS, COLUMNS.IMAGE, COLUMNS.CLICKS, COLUMNS.IMPRESSIONS, COLUMNS.ACTIONS],
};

<template>
  <div>
    <div v-if="template" class="mb-8">
      <sun-form @submit="submitTemplate">
        <card-form>
          <template #title>Template</template>
          <template #form>
            <div class="flex flex-wrap items-center justify-end w-full text-gray-700 xl:w-auto">
              <form-row>
                <template #left>
                  <sun-label-group text="Title">
                    <asterix-input
                      id="template-title"
                      v-model="template.name"
                      :disabled="readOnly"
                      type="text"
                      name="title"
                      :minlength="3"
                      :maxlength="50"
                      validate-on-blur
                      placeholder="A title..."
                      required="required"
                    />
                  </sun-label-group>
                </template>
                <template #right>
                  <sun-label-group text="Description">
                    <sun-text-area
                      id="template-description"
                      :disabled="readOnly"
                      :value="template.description"
                      type="text"
                      name="description"
                      :minlength="3"
                      :maxlength="100"
                      placeholder="A description..."
                      @change="template.description = $event.value"
                    />
                  </sun-label-group>
                </template>
              </form-row>
            </div>
            <div class="flex w-1/2 mt-2">
              <save-button id="template-submit" :disabled="readOnly" :loading="isLoading" />
            </div>
          </template>
        </card-form>
      </sun-form>
    </div>
    <div class="flex justify-end">
      <sun-button variant="pill" class="text-xs right-0 custom-p-1" :disabled="readOnly" @click="goToNewCreative">
        + New creative
      </sun-button>
    </div>
    <sun-data-table :headers="headers" :content="items" hoverable :loading="isLoading" class="mt-2">
      <template #[`col.title`]="{ columnClass }">
        <sun-data-table-cell :class="columnClass">
          {{ insertion.template.name }}
        </sun-data-table-cell>
      </template>

      <template #[`col.image`]="{ item }">
        <sun-data-table-cell class="font-bold">
          <img alt="creative image" :src="item.urlFile" width="70" />
        </sun-data-table-cell>
      </template>

      <template #[`col.status`]="{ item }">
        <sun-data-table-cell>
          <status-pill :status="item.status.value" />
        </sun-data-table-cell>
      </template>

      <template #[`col.actions`]="{ item }">
        <sun-data-table-cell>
          <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
        </sun-data-table-cell>
      </template>

      <template #empty>
        <asterix-no-data class="bg-white text-center" />
      </template>
    </sun-data-table>

    <delete-modal :open="showModal" :loading="isLoading" @cancel="showModal = false" @confirm="deleteCreative">
      <template #description>{{ deleteModalDescription }}</template>
    </delete-modal>

    <router-view v-bind="$attrs" @cancel="onCancelModal" @new-template="newTemplate" @update="getTableItems" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { SocialAudienceApi } from '@/services/api/SocialAudienceApi';
import { getCreatives, deleteCreative } from '@/services/modules/socialAudience/creative/native';
import { edit } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative/edit';
import { getInsertionByCampaign, updateInsertion } from '@/services/modules/socialAudience/insertion';
import { create } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative/create';
import { creative } from '@/router/private/modules/socialAudience/demand/campaign/editForm/creative';
import StatusPill from '@/components/atoms/StatusPill';
import { CONFIG } from './config';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import CardForm from '@/components/atoms/CardForm';
import FormRow from '@/components/atoms/FormRow/FormRow';
import AsterixInput from '@/components/atoms/AsterixInput';
import SaveButton from '@/components/atoms/SaveButton';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'CreativeListNative',
  components: {
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    DeleteModal,
    StatusPill,
    CardForm,
    FormRow,
    AsterixInput,
    SaveButton,
  },
  mixins: [deleteModalMixin],
  props: {
    campaignId: {
      type: String,
      default: () => null,
    },
    readOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    headers: CONFIG.defaultColumns,
    actions: [{ name: 'Edit' }, { name: 'Delete' }],
    items: [],
    currentApi: new SocialAudienceApi(),
    deleteModalDescription: '',
    insertion: null,
    isLoading: false,
    creativeToDelete: null,
    template: null,
  }),
  async created() {
    try {
      this.isLoading = true;
      const { data } = await getInsertionByCampaign(this.campaignId);
      this.insertion = data[0];
      this.template = this.insertion.template.clone();
      await this.getTableItems();
    } catch (error) {
      if (error.code !== HTTPStatusCode.Cancel) {
        this.createToast(Toast.error(`Can't get insertion.`, error.message));
      }
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    async onActionClick(event, item) {
      if (this.readOnly) {
        this.createToast(Toast.error('Error', 'The campaign has been completed'));
        return;
      }

      switch (event.name) {
        case 'Edit':
          this.routeToEditCreative(item);
          break;
        case 'Delete':
          this.routeToDeleteCreative(item);
          break;
      }
    },
    routeToDeleteCreative(item) {
      this.deleteModalDescription = `The creative is going to be deleted.`;
      this.showModal = true;
      this.creativeToDelete = item;
      this.openModal({ id: item.id });
    },
    routeToEditCreative(item) {
      this.$router.push({
        ...edit,
        params: {
          creativeId: item.id,
        },
      });
    },
    async getTableItems() {
      const { data } = await getCreatives(this.insertion.id);
      this.items = data;
    },
    async deleteCreative() {
      try {
        this.isLoading = true;
        await deleteCreative(this.creativeToDelete);
        this.createToast(Toast.success('Creative deleted', `The creative was deleted successfully.`));
      } catch (error) {
        this.createToast(Toast.error('Creative not deleted', error.message));
      } finally {
        this.isLoading = false;
        this.showModal = false;
        this.getTableItems();
      }
    },
    async goToNewCreative() {
      await this.$router.push({
        ...create,
        params: {
          insertionId: this.insertion.id,
          showTemplate: !this.insertion.hasTemplate(),
        },
      });
    },
    async onCancelModal() {
      await this.$router.push(creative);
    },
    newTemplate(template) {
      this.template = template;
      this.saveTemplate();
    },
    submitTemplate(form) {
      if (!form.valid) return;
      this.saveTemplate();
    },
    async saveTemplate() {
      this.isLoading = true;
      try {
        this.insertion.template = this.template.clone();
        await updateInsertion(this.insertion);
        this.createToast(Toast.success('Template updated', 'The template was updated successfully.'));
      } catch (error) {
        this.createToast(Toast.error('Template not updated', error.message));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<template>
  <span :class="`px-2 py-1 leading-tight align-middle inline-flex items-center bg-${computedColor}-100 rounded-full`">
    <svg fill="currentColor" viewBox="0 0 8 8" :class="`h-2 w-2 text-${computedColor}-500`">
      <circle cx="4" cy="4" r="3" />
    </svg>
    <span :class="`ml-2 text-xs font-bold text-${computedColor}-900`">{{ status }}</span>
  </span>
</template>

<script>
export default {
  name: 'StatusPill',
  props: {
    color: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: 'Unknown',
    },
  },
  computed: {
    computedColor() {
      if (this.color) return this.color;

      switch (this.status.toUpperCase()) {
        case 'OK':
        case 'ACTIVE':
        case 'FOUND':
        case 'INTEGRATED':
          return 'green';
        case 'CREATED':
          return 'teal';
        case 'DRAFT':
        case 'LOADING':
          return 'yellow';
        case 'PENDING_APPROVAL':
          return 'orange';
        case 'APPROVED':
        case 'CHECKING':
          return 'blue';
        case 'BANNED':
        case 'DISAPPROVED':
        case 'FAIL':
        case 'DELETED':
        case 'NOT_FOUND':
        case 'FAILED':
          return 'red';
        case 'PAUSED':
          return 'indigo';
        case 'INACTIVE':
        case 'FINISHED':
        case 'CANCELED':
          return 'pink';
        case 'UNKNOWN':
        default:
          return 'gray';
      }
    },
  },
};
</script>

<template>
  <div class="flex relative">
    <sun-input
      v-model="input"
      :disabled="disabled"
      required="required"
      type="number"
      placeholder="0"
      :text-error="messageError"
      :error="error"
      @change="onChange"
      @enter="onChange"
    />
    <button-group
      v-model="btnSelected"
      :disabled="disabled"
      :options="options"
      :text-by="textBy"
      :track-by="trackBy"
      return-object
      class="bg-white absolute rounded-lg z-1 h-10 shadow right-0"
      @change="onChange()"
    />
  </div>
</template>

<script>
import ButtonGroup from '@/components/molecules/shared/AsterixButtonGroup.vue';

export default {
  name: 'CostFilter',
  components: { ButtonGroup },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    trackBy: {
      type: String,
      default: 'id',
    },
    textBy: {
      type: String,
      default: 'symbol',
    },
    textError: {
      type: String,
      default: '',
    },
    error: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    input: null,
    btnSelected: null,
  }),
  computed: {
    messageError() {
      return this.textError || 'The field is required';
    },
  },
  watch: {
    value: 'initialSelection',
  },
  created() {
    this.initialSelection(this.value);
  },
  methods: {
    initialSelection(option) {
      this.btnSelected = option;
      // I need to add + because type of sun-input is number
      this.input = +option.input;
    },
    onChange($event) {
      if (!this.disabled && (typeof $event === 'undefined' || $event.validation.isValid)) {
        const rta = {
          // filters values, show id on url and text on pill
          value: `[${this.btnSelected[this.trackBy]}]${this.input}`,
          text: `${this.btnSelected[this.textBy]} ${this.input}`,
          // basic and generic values
          id: this.btnSelected[this.trackBy],
          input: this.input,
        };
        this.$emit('input', rta);
        this.$emit('change', rta);
      }
    },
  },
};
</script>
